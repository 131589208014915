const MenuIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 0H0V35H35V0ZM33 2H22V13H33V2Z"
        fill="#1E2C35"
      />
    </svg>
  );
};

export default MenuIcon;
