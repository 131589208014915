import Close from './icons/Close';

export interface MenuItem {
  title: string;
  to: string;
}

interface Props {
  menu: MenuItem[];
  scrollTo: (selector: string) => void;
  closeMenu: () => void;
}

const Menu = ({ menu, scrollTo, closeMenu }: Props) => {
  return (
    <div className="menu fixed bg-T100 top-0 left-0 w-screen h-screen z-10 text-white pt-5 px-10">
      <h1 className="font-bold text-3xl">Teerapon</h1>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <ul className="text-center text-2xl">
          {menu.map((item) => (
            <li
              key={item.to}
              className="cursor-pointer mb-5"
              onClick={() => {
                scrollTo(item.to);
                closeMenu();
              }}
            >
              {item.title}
            </li>
          ))}
          <li><a href="/Teerapon Nampakdee Resume.pdf" target="_blank" rel="noreferrer">Resume</a></li>
        </ul>
      </div>
      <div
        className="absolute bottom-0 left-1/2 -translate-x-1/2 mb-10"
        onClick={closeMenu}
      >
        <Close />
      </div>
    </div>
  );
};

export default Menu;
