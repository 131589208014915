const Underline = () => {
  return (
    <svg
      width="300"
      height="10"
      viewBox="0 0 300 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M300 0H185V10H300V0Z" fill="#1E2C35" />
      <path d="M300 0H185V10H300V0Z" fill="#1E2C35" />
      <path d="M300 0H185V10H300V0Z" fill="#1E2C35" />
      <path d="M300 0H185V10H300V0Z" fill="#1E2C35" />
      <path d="M50 0H0V10H50V0Z" fill="#1E2C35" />
      <path d="M50 0H0V10H50V0Z" fill="#1E2C35" />
      <path d="M50 0H0V10H50V0Z" fill="#1E2C35" />
      <path d="M50 0H0V10H50V0Z" fill="#1E2C35" />
      <path d="M160 0H75V10H160V0Z" fill="#1E2C35" />
      <path d="M160 0H75V10H160V0Z" fill="#1E2C35" />
      <path d="M160 0H75V10H160V0Z" fill="#1E2C35" />
      <path d="M160 0H75V10H160V0Z" fill="#1E2C35" />
    </svg>
  );
};

export default Underline;
