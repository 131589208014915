import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

const ContactSection = () => {
  const contacts = [
    {
      title: 'Email',
      link: 'mailto:trp.aof@gmail.com'
    },
    {
      title: 'Github',
      link: 'https://github.com/teerapon19'
    },
    {
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/in/teerapon/'
    },
    {
      title: 'Resume',
      link: '/Teerapon Nampakdee Resume.pdf'
    }
  ];

  const contactHeaderRef = useRef(null);
  const contactRef = useRef(null);

  useLayoutEffect(() => {
    gsap.fromTo(
      contactHeaderRef.current,
      { x: -1500 },
      {
        duration: 0.4,
        x: 0,
        ease: 'none',
        scrollTrigger: {
          id: 'contract',
          trigger: contactHeaderRef.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
    gsap.fromTo(
      contactRef.current,
      {
        opacity: 0,
        scale: 2
      },
      {
        duration: 0.4,
        opacity: 1,
        scale: 1,
        ease: 'bounce.out',
        delay: 0.8,
        scrollTrigger: {
          id: 'contract',
          trigger: contactRef.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
  }, []);

  return (
    <div id="contact" className="contact-section min-h-screen xl:px-44">
      <div className="flex flex-col xl:flex-row xl:justify-between items-center min-h-screen">
        <h1
          ref={contactHeaderRef}
          className="uppercase text-5xl xl:text-8xl font-bold xl:py-0 pb-24 pt-44"
        >
          Contact
        </h1>
        <div ref={contactRef}>
          <ul className="xl:text-right text-center text-2xl xl:text-4xl">
            {contacts.map((item, index) => (
              <li
                key={index}
                className={`hover:scale-150 hover:-translate-x-10 transition-transform ${
                  index !== contacts.length ? 'mb-16' : ''
                }`}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className="text-center font-light mb-5">
        <span className="xl:mr-10 xl:inline block">© Teerapon 2022</span>
        <span>
          Design and Develop by <u>Teerapon Npd</u>
        </span>
      </p>
    </div>
  );
};

export default ContactSection;
