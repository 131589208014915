import { useRef } from 'react';
import './App.scss';
import AboutSection from './sections/AboutSection';
import CertificateSection from './sections/CertificateSection';
import ContactSection from './sections/ContactSection';
import HeroSection from './sections/HeroSection';
import SkillsAndToolsSection from './sections/SkillsAndToolsSection';
import WorkExperienceSection from './sections/WorkExperienceSection';

function App() {
  const mouseRef = useRef<HTMLSpanElement>(null);

  const mouseEvent = (e: MouseEvent) => {
    if (mouseRef.current?.style.opacity === '') {
      mouseRef.current?.style.setProperty('opacity', '1');
    }
    mouseRef.current?.style.setProperty(
      'transform',
      `translate(${e.pageX - Number(mouseRef.current?.clientWidth) / 2}px, ${
        e.pageY - Number(mouseRef.current?.clientHeight) / 2
      }px)`
    );
  };
  // document.body.addEventListener('mouseover', mouseEvent);
  document.body.addEventListener('mousemove', mouseEvent);

  return (
    <div className="App">
      <HeroSection />
      <AboutSection />
      <SkillsAndToolsSection />
      <CertificateSection />
      <WorkExperienceSection />
      <ContactSection />
      <span ref={mouseRef} id="circle" className="circle"></span>
    </div>
  );
}

export default App;
