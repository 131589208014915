import { useLayoutEffect, useRef } from 'react';
import { useEffect, useState } from 'react';
import CertificatesJSON from '../assets/certificates.json';
import gsap from 'gsap';

interface Skill {
  title: string;
  from: string;
  issueDate: string;
  link: string;
}

const CertificateSection = () => {
  const certificateHeader = useRef(null);

  const [pages, setPages] = useState<Array<Skill[]>>([]);

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const AMOUNT_PER_PAGE = 5;
    const pagesLocal = [];
    let skillPerPage = [];
    for (const [index, skill] of CertificatesJSON.entries()) {
      skillPerPage.push(skill);
      if (index % AMOUNT_PER_PAGE === AMOUNT_PER_PAGE - 1) {
        pagesLocal.push(skillPerPage);
        skillPerPage = [];
      }
    }
    setPages(pagesLocal);
  }, []);

  useLayoutEffect(() => {
    gsap.fromTo(
      certificateHeader.current,
      {
        x: -1500
      },
      {
        x: 0,
        duration: 0.5,
        scrollTrigger: {
          id: 'certificate',
          trigger: certificateHeader.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
  }, []);

  return (
    <div id="certificate" className="certificate-section min-h-screen w-screen">
      <div
        ref={certificateHeader}
        className="uppercase text-T100 -mb-2 xl:-mb-4 xl:px-44 font-bold text-5xl xl:text-9xl px-5"
      >
        Certificate
      </div>
      <div className="bg-T100 min-h-screen w-screen xl:px-44 relative">
        <div className="xl:block hidden">
          <div className="grid grid-cols-2 text-white py-28 px-10">
            {CertificatesJSON.map((item, index) => (
              <a key={index} href={item.link} target="_blank" rel="noreferrer">
                <div className="mb-20">
                  <div className="font-bold text-lg h-14">{item.title}</div>
                  <div className="flex">
                    <div className="w-28">{item.from}</div>
                    <div className="text-md font-light">{item.issueDate}</div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
        <div className="block xl:hidden text-white px-10 pt-20">
          {pages.length > 0 &&
            pages[currentPage].map((item, index) => (
              <a key={index} href={item.link} target="_blank" rel="noreferrer">
                <div className="pb-14">
                  <div className="font-bold text-lg">{item.title}</div>
                  <div className="flex">
                    <div className="w-28">{item.from}</div>
                    <div className="text-md font-light">{item.issueDate}</div>
                  </div>
                </div>
              </a>
            ))}
          <div className="text-center mb-8 pb-10">
            {pages.map((_, index) => (
              <div
                className={`w-10 h-10 inline-block bg-white text-T100 relative cursor-pointer ${
                  index !== pages.length - 1 ? 'mr-2' : ''
                } ${currentPage !== index ? 'rounded-full' : ''}`}
                key={index}
                onClick={() => {
                  setCurrentPage(index);
                }}
              >
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold">
                  {index + 1}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateSection;
