import gsap from 'gsap';
import { useLayoutEffect, useRef, useState } from 'react';
import MenuIcon from '../components/icons/MenuIcon';
import Underline from '../components/icons/Underline';
import Menu, { MenuItem } from '../components/Menu';

function HeroSection() {
  const menu: Array<MenuItem> = [
    {
      title: 'About',
      to: '#about'
    },
    {
      title: 'Skills',
      to: '#skills'
    },
    {
      title: 'Certificate',
      to: '#certificate'
    },
    {
      title: 'Experience',
      to: '#experience'
    },
    {
      title: 'Contact',
      to: '#contact'
    }
  ];

  const textHeroEl = useRef(null);
  const navHeroEl = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const scrollTo = (selector: string) => {
    const section = document.querySelector(selector);
    if (section == null) return;
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const closeMenu = () => {
    setMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  useLayoutEffect(() => {
    gsap.fromTo(
      textHeroEl.current,
      {
        y: -800,
        x: -800,
        rotate: -90
      },
      {
        x: 0,
        y: 0,
        rotate: 0,
        ease: 'bounce.out',
        duration: 0.8
      }
    );
    gsap.fromTo(
      navHeroEl.current,
      {
        y: -20,
        opacity: 0
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.4,
        delay: 0.8
      }
    );
  });

  return (
    <div className="hero-section min-h-screen mb-20 px-5 xl:px-10">
      <nav ref={navHeroEl} className="flex justify-between items-center mt-5">
        <h1 className="font-bold text-3xl">Teerapon</h1>
        <ul className="gap-12 font-light xl:flex hidden">
          {menu.map((item) => (
            <li
              key={item.to}
              className="cursor-pointer hover:scale-150 transition-transform"
              onClick={() => scrollTo(item.to)}
            >
              {item.title}
            </li>
          ))}
          <li><a href="/Teerapon Nampakdee Resume.pdf" target="_blank" rel="noreferrer">Resume</a></li>
        </ul>
        <div
          className="xl:hidden cursor-pointer"
          onClick={() => {
            setMenuOpen(true);
            document.body.style.overflow = 'hidden';
          }}
        >
          <MenuIcon />
        </div>
      </nav>

      <div
        ref={textHeroEl}
        className="absolute top-1/2 -translate-y-1/2 xl:ml-52"
      >
        <p className="font-light mb-4">I'm a</p>
        <h1 className="xl:text-6xl text-4xl font-bold mb-4">
          Full stack developer
        </h1>
        <Underline />
      </div>

      {menuOpen && (
        <Menu menu={menu} scrollTo={scrollTo} closeMenu={closeMenu} />
      )}
    </div>
  );
}

export default HeroSection;
