import { useLayoutEffect } from 'react';
import Experience from '../assets/work-experience.json';
import gsap from 'gsap';
import { useRef } from 'react';

const WorkExperienceSection = () => {
  const experienceSignRef = useRef(null);
  const experienceContentRef = useRef(null);

  useLayoutEffect(() => {
    gsap.fromTo(
      experienceSignRef.current,
      {
        x: -1400,
        rotate: 0,
        duration: 1
      },
      {
        x: 0,
        rotate: 90,
        duration: 1,
        scrollTrigger: {
          id: 'experience',
          trigger: experienceSignRef.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
    gsap.fromTo(
      experienceContentRef.current,
      {
        y: 200
      },
      {
        y: 0,
        duration: 0.4,
        scrollTrigger: {
          id: 'experience',
          trigger: experienceContentRef.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
  }, []);

  return (
    <div id="experience" className="work-experience-section xl:px-44 xl:pt-44">
      <div className="flex">
        <div className="w-1/2 h-1600">
          <div ref={experienceSignRef} className="rotate-90 relative">
            <h1
              className="uppercase text-T100 font-bold absolute left-1/2 xl:-mt-16 -mt-8 text-6xl xl:text-[247px] xl:ml-0 ml-10"
              style={{
                lineHeight: '0.73'
              }}
            >
              Work <br />
              Experience
            </h1>
          </div>
        </div>
        <div ref={experienceContentRef} className="pt-10 xl:w-1/2 w-full">
          {Experience.map((item, index) => (
            <div key={index} className="mb-20">
              <div className="font-bold text-lg mb-5">{item.title}</div>
              <div className="mb-2">{item.date}</div>
              <div>
                <ul className="list-disc list-inside font-light">
                  {item.description.map((des, index) => (
                    <li key={index}>{des}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkExperienceSection;
