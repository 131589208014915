import { useEffect, useRef } from 'react';
import gsap from 'gsap';

const SkillsAndToolsSection = () => {
  const skillHeaderRef = useRef(null);
  const skillRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      skillHeaderRef.current,
      { x: -1800 },
      {
        duration: 0.4,
        x: 0,
        ease: 'none',
        scrollTrigger: {
          id: 'skills',
          trigger: skillHeaderRef.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
    gsap.fromTo(
      skillRef.current,
      { x: 1800 },
      {
        duration: 0.4,
        x: 0,
        ease: 'none',
        scrollTrigger: {
          id: 'skills',
          trigger: skillRef.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
  });

  return (
    <div>
      <div
        id="skills"
        className="skills-and-tools-section xl:px-44 min-h-screen xl:mb-0 mb-44 px-5"
      >
        <h1
          ref={skillHeaderRef}
          className="uppercase text-4xl xl:text-8xl font-bold xl:pb-32 py-20"
        >
          Skills &amp; Tools
        </h1>
        <div ref={skillRef}>
          <div className="grid xl:grid-cols-4 grid-cols-2 gap-4 text-xl xl:ml-5">
            <div>Go</div>
            <div>Fiber</div>
            <div>HTML</div>
            <div>CSS</div>
            <div>VSCode</div>
            <div>Unity</div>
            <div>Nodejs</div>
            <div>Tailwind</div>
            <div>Web3</div>
            <div>EVM</div>
            <div>Blockchain</div>
            <div>Solidity</div>
            <div>Hardhat</div>
            <div>JavaScript</div>
            <div>TypeScript</div>
            <div>React</div>
            <div>Vue</div>
            <div>MongoDB</div>
            <div>PostgreSQL</div>
            <div>Firebase</div>
            <div>Docker</div>
            <div>Git</div>
            <div>Express.js</div>
            <div>Figma</div>
            <div>UX/UI</div>
            <div>Kotlin</div>
            <div>Android Studio</div>
            <div>C</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsAndToolsSection;
