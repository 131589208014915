import { useRef } from 'react';
import gsap from 'gsap';
import { useEffect } from 'react';

function AboutSection() {
  const aboutRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      aboutRef.current,
      { x: 1800 },
      {
        x: 0,
        duration: 0.4,
        delay: 0.4,
        ease: 'none',
        scrollTrigger: {
          id: 'about',
          trigger: aboutRef.current,
          start: 'top bottom'
          // markers: true
        }
      }
    );
  });

  return (
    <div>
      <div
        id="about"
        ref={aboutRef}
        className="about-section min-h-screen xl:px-44 pt-20 pb-40 xl:pb-10 px-5"
      >
        <div className="mb-44">
          <h1 className="uppercase text-2xl xl:text-4xl font-bold mb-14">
            About Me
          </h1>
          <p className="font-light indent-10">
            Hello, My name is <b>Teerapon Nampakdee</b>. My passion for code
            began when I played Minecraft when I was 15 years old. I had
            installed a Minecraft server for playing with my friends. I had
            started learning how to develop a website by using WordPress. After
            my first year at university, I decided to be a freelance android
            application developer at FastWork for a year of experience and other
            freelance work with about two years experience. At my computer
            engineering bachelor's subject that relates to programming, They
            have a subject project that makes me familiar with programming along
            with my freelance experience. I do love to develop designs and
            plans. Being a full-stack developer is my pleasure and passion.
          </p>
        </div>
        <div>
          <h1 className="uppercase text-2xl xl:text-4xl font-bold mb-14">
            Education
          </h1>
          <p className="font-light">
            Bachelor of Engineering (Computer Engineering) Khon Kaen University
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
